/*Good luck to everyone trying to edit this stylesheet! 
It was first created in 2018 and has only been modified during all the numerous design changes the site has gone through.
That is why it contains a lot of unclean and unneccessary code */

:root {
	--seperator-color: #4389f9;
	--mild-highlight-color:#d6d6ff;
	--invert-mild-higlight-color: #4389f9; 
	--menu-color: transparent;
	--header-color: rgb(223, 239, 255);
	--mobile-menu-color: #0f0f0f;
	--places-color: white;
	--border-color: #ddd;
	--text-color: black;
	--quick-color: rgba(255, 255, 255, 0.5);
	--quick-invert-color: rgba(0, 0, 0, 0.5);
	--login-gradient: url("./Backgrounds/Gradient.webp");
	--login-gradient-white: url("./Backgrounds/Gradient_light.webp");
	--login-bg: var(--login-gradient);
	--invert-amount: 0%;
	--background-color: #0f0f0f;
	--shadow-color: rgba(0, 0, 0, 1);
}

html {
	background-color: var(--background-color);
}

body,
html {
	height: 100%;
	margin: 0;
}

#site {
	height: 100%;
	width: 100%;
}

h1,
h2,
#logo,
#salainen,
.linkLookALike {
	color: var(--text-color);
}

@media (prefers-reduced-motion) {
	* {
		transition: none !important;
		animation: none !important;
	}

	#lomaDiv {
		opacity: 1 !important;
	}
}

#ErrorContainer {
	display: flex;
	width: 100vw;
	height: 100%;
	justify-content: center;
	align-items: flex-start;
	padding-top: 2em;
}

#ErrorHeader {
	font-family: "Cabin Condensed", sans-serif;
	font-size: 40vmin;
	margin: 0;
	position: relative;
}

#ErrorHeader::after {
	content: "";
	width: 100%;
	height: 100%;
	mix-blend-mode: hue;
	background-clip: text;
	background-image: linear-gradient(to right, aliceblue, blue);
	position: absolute;
	top: 0;
	left: 0;
}

.loader {
	font-size: 1rem;
	padding: 1em;
	height: auto !important;
	justify-content: flex-start;
}

.loader-inner {
	display: flex;
	justify-content: center;
}

#infoPage * {
	box-sizing: border-box;
}

#infoPage {
	width: 100vw;
	height: 100%;
	background: var(--login-bg) repeat-y;
	background-size: 100vw 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	perspective: 50cm;
	overflow: hidden;
}

.cube {
	transform-style: preserve-3d;
	width: 80vmin;
	height: 80vmin;
	transition: transform 0.5s ease-in-out;
	font-size: 18vmin;
	transform: translateZ(-40vmin) rotateY(0deg);
}

.cubeSide {
	position: absolute;
	height: 80vmin;
	width: 80vmin;
	background: var(--cube-color);
	box-shadow: black 0px 0px 10px;
}

.infoContent {
	height: 100%;
	width: 100%;
}

.cubeSide1 {
	transform: rotateY(0deg) translateZ(40vmin);
}

.infoContent1 {
	display: flex;
	justify-content: center;
	align-items: center;
}

.infoContent1 > div {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-family: "Cabin Condensed", sans-serif;
	border: solid var(--seperator-color) 2px;
	width: 60vmin;
	height: 60vmin;
	background-color: var(--logo-color);
	box-shadow: var(--seperator-color) 0px 0px 6px 1px,
		black 0px 0px 2px 2px inset;
	border-radius: 7.5%;
}

.infoContent1 h1 {
	font-size: 140%;
	color: white;
	margin: 0;
}

.infoContent1 h2 {
	font-size: 50%;
	color: var(--lomalaskuri-color);
	margin: 0;
}

.infoContent1 p {
	font-size: 30%;
	color: var(--text-color);
	margin: 0;
}

.cubeSide2 {
	transform: rotateX(-90deg) translateZ(40vmin);
}

.infoGrid {
	display: grid;
	grid-template-columns: 65% auto;
	column-gap: 0.5em;
	grid-template-rows: auto 1fr 1fr;
	grid-auto-flow: row;
	padding: 1em;
	font-family: "Cabin Condensed", sans-serif;
	font-size: 21%;
}

.infoGrid h1 {
	color: var(--header-color);
	grid-column: 1 / span 2;
	font-size: 180%;
	margin: 0.5em 1em 1em 0em;
}

.infoGrid p:first-of-type {
	margin: 0;
}

.cubeSide3 {
	transform: rotateX(-180deg) translateZ(40vmin);
}

.cubeSide4 {
	transform: rotateX(-270deg) translateZ(40vmin);
}

.pageIndicator {
	position: fixed;
	right: calc(50% - 50vmin);
	top: 50%;
	transform: translateY(-50%);
	height: 5em;
	display: flex;
	justify-content: space-evenly;
	flex-direction: column;
}

.pageIndicator > div {
	background-color: var(--lomalaskuri-color);
	border-radius: 50%;
	width: 1em;
	height: 1em;
	box-shadow: black 0px 0px 20px;
	position: relative;
	transition: transform 0.1s;
}

.pageIndicator > div:hover {
	transform: scale(1.2);
}

.pageIndicator > div::after {
	opacity: 0%;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	background-color: black;
	border-radius: 50%;
	width: 60%;
	height: 60%;
	transition: opacity 0.5s ease-in-out;
	pointer-events: none;
	content: "";
}

.pageIndicator.onSide1 > div:nth-of-type(1)::after {
	opacity: 100%;
}

.pageIndicator.onSide2 > div:nth-of-type(2)::after {
	opacity: 100%;
}

.pageIndicator.onSide3 > div:nth-of-type(3)::after {
	opacity: 100%;
}

.infoText {
	font-family: "Roboto", Arial, sans-serif;
	color: var(--text-color);
	font-size: 80%;
	grid-row: 2 / span 3;
}

.infoText.half {
	grid-column: 1;
}

.infoText.full {
	grid-column: 1 / span 2;
}

.infoImg {
	margin: 0;
	font-size: 0.5em;
	text-align: center;
	color: var(--text-color);
}

.infoImg img {
	width: 100%;
	margin-bottom: 0.75em;
	transition: transform 0.1s;
}

.infoImg img:hover {
	transform: scale(1.1);
}

.backArrow {
	position: fixed;
	filter: invert(1);
	transform: rotate(180deg);
	top: 1em;
	left: 0;
}

.backArrow img {
	width: 2em;
}

.highlight {
	color: var(--seperator-color);
}
.quickRuokalista .highlight {
	color: var(--mild-highlight-color)
}
.foodList .highlight{
	color: var(--invert-mild-higlight-color);
}
@keyframes suggest {
	0% {
		transform: rotate(90deg) translateX(-10px);
	}

	100% {
		transform: rotate(90deg) translateX(0px);
	}
}

.scrollSign {
	position: fixed;
	width: 3vmax;
	height: 3vmax;
	filter: invert(1);
	transform: rotate(90deg);
	bottom: 1.5em;
	animation: suggest infinite 0.6s ease-in-out alternate;
}

@media (orientation: portrait) and (max-width: 960px) {
	/*when used on a mobile device, convert the cube to a rectangle. That way it can ocupy more of the screen. */
	.cube {
		height: 80vh;
		width: 100vw;
		font-size: 15vh;
		transform: translateZ(-80vh);
	}

	.infoContent1 > div {
		width: 75vw;
		height: 75vw;
	}

	.cubeSide1 {
		transform: rotateX(0deg) translateZ(40vh);
	}

	.cubeSide2 {
		transform: rotateX(-90deg) translateZ(40vh);
	}

	.cubeSide3 {
		transform: rotateX(-180deg) translateZ(40vh);
	}

	.cubeSide4 {
		transform: rotateX(-270deg) translateZ(40vh);
	}

	.cubeSide {
		height: 80vh;
		width: 100vw;
	}

	.infoContent1 {
		font-size: 18vw;
	}

	.infoGrid {
		grid-template-columns: 1fr 1fr;
		grid-template-rows: minmax(max-content, 5em) 1fr 1fr;
	}

	.infoContent2 h1 {
		margin: 0 0 0.5em 0;
	}

	.infoText.half {
		grid-column: 1 / span 2;
		grid-row: 2;
	}

	.infoImg {
		max-width: 100%;
		max-height: 100%;
	}

	.pageIndicator {
		left: 50%;
		top: 1.5em;
		transform: translate(-50%);
		flex-direction: row;
		width: 5em;
		font-size: 20px;
	}
}

.square-spin > div {
	background: var(--seperator-color) !important;
	border: solid 1px var(--text-color) !important;
	height: 3em !important;
	width: 3em !important;
	max-height: 50px;
	max-width: 50px;
	animation: spin 2s infinite ease-in-out;
	text-align: center;
	color: white;
}

.square-spin > div::after {
	font-family: "Cabin Condensed", sans-serif;
	content: "L";
	font-size: 2.5em;
}

@keyframes spin {
	0% {
		transform: perspective(9cm) rotate3d(0, 0, 0, 0deg);
	}

	50% {
		transform: perspective(9cm) rotate3d(1, 0, 0, 360deg);
	}

	100% {
		transform: perspective(9cm) rotate3d(0, 0, 0, 0eg);
	}
}

#typingDiv {
	display: flex;
	justify-content: space-between;
	height: 3em;
}

.Stat {
	color: white;
	font-family: Oswald;
	font-size: 1.5em;
	margin: 0.1em;
	padding: 0em 1em 0em 1em;
}

#foodListPos {
	width: 100vw;
	justify-content: center;
	align-items: center;
	display: flex;
}

.quickBox {
	margin-top: 1em;
	display: flex;
	justify-content: space-between;
	background-color: var(--quick-color);
	opacity: 0;
	height: 100%;
	border-radius: 15px;
	box-shadow: 0px 0px 20px var(--shadow-color);
}

.quickBox.corona {
	box-shadow: inset red 0 8px 0px 0px;
}

.quickBox.active {
	animation: disappearLeft 1s;
	opacity: 0;
}

.quickContent h2 {
	text-align: center;
	align-self: center;
	font-size: 250%;
	margin: 0;
}

@keyframes appearLeft {
	0% {
		opacity: 0;
		transform: translate(-40px, 0px);
	}

	100% {
		opacity: 1;
		transform: translate(0px, 0px);
	}
}

@keyframes appearRight {
	0% {
		opacity: 0;
		transform: translate(40px, 0px);
	}

	100% {
		opacity: 1;
		transform: translate(0px, 0px);
	}
}

@keyframes disappearLeft {
	0% {
		opacity: 1;
		transform: translate(0px, 0px);
	}

	100% {
		opacity: 0;
		transform: translate(-40px, 0px);
	}
}

.quickBox.anim {
	animation: appearLeft 1s;
	opacity: 1;
}

.quickBoxLeft {
	width: 90%;
	font-family: Oswald;
}

.quickBoxLeft.full {
	width: 100%;
}

.quickTitle {
	padding-left: 1em;
	padding-top: 0.5em;
	border-bottom: 1px solid var(--seperator-color);
	height: 2em;
	margin: 0;
	grid-row: 1;
}

.quickBoxLeft .timerTitle {
	font-size: calc(10px + 2.5vw);
	color: var(--text-color);
	text-shadow: none;
}

.quickBoxRight {
	height: 100%;
	border-left: 1px solid var(--seperator-color);
	width: 10%;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 0px 15px 15px 0px;
}

.quickBoxRight:hover {
	background-color: var(--quick-invert-color);
}

.quickBoxRight:hover .arrow > svg {
	filter: drop-shadow(0px 0px 15px var(--seperator-color));
}

.quickContent {
	grid-row: -1 / span 2;
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 40% 60%;
	position: relative;
}

.quickText {
	background-color: var(--quick-invert-color);
	width: 100%;
	box-sizing: border-box;
	margin-bottom: 0;
	color: white;
	grid-row: 2;
	font-size: 1.5em;
	padding-left: 0.5em;
}

.quickRuokalista {
	grid-row: span 2;
}

.quickLoader {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.arrow {
	display: inline-block;
	background: none;
	border: none;
}

#show {
	font-family: "Oswald", sans-serif;
	text-align: center;
	color: var(--text-color);
}

#foodList {
	background-color: var(--mobile-menu-color);
	padding: 2em;
	text-align: left;
}
.food p {
	margin:0;
	margin-left: 1em;
}

.SettingBtn {
	color: white !important;
}

.linkLookALike {
	background: none !important;
	border: none;
	padding: 0 !important;
	cursor: pointer;
	text-decoration: none;
	margin: 0 1em 0 1em;
	font-size: 20px;
	font-family: "Oswald", sans-serif;
	font-display: swap;
}

.quickContainerContainer {
	display: flex;
	justify-content: center;
	width: 100%;
	margin-bottom: 3em;
}

.quickContainer {
	display: grid;
	max-width: 2500px;
	width: 100%;
	gap: 1em 0em;
}

.quickImage {
	background-color: var(--quick-invert-color);
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.quickTilastot {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
}

.quickImage > img {
	height: 100%;
	position: absolute;
	transition: 0.2s;
	transition-property: opacity;
}

#places > a {
	color: var(--text-color) !important;
}

.checkbox {
	margin: 0 0.5em;
}

.checkbox input[type="checkbox"] {
	opacity: 0;
}

.checkbox label {
	position: relative;
	display: inline-block;
	padding-left: 1.5em;
}

.checkbox label::before,
.checkbox label::after {
	position: absolute;
	content: "";
	display: inline-block;
}

.checkbox label::before {
	height: 1em;
	width: 1em;
	background-color: white;
	transition: 0.2s ease-in-out;
	border: 1px solid;
	left: 0;
	top: 3px;
	border-radius: 30%;
}

.checkbox input[type="checkbox"]:checked + label::before {
	background: var(--seperator-color);
}

.checkbox label::after {
	height: 5px;
	width: 9px;
	border-left: 2px solid;
	border-bottom: 2px solid;
	transform: rotate(-45deg);
	left: 4px;
	top: 7px;
}

.checkbox input[type="checkbox"] + label::after {
	content: none;
}

.checkbox input[type="checkbox"]:checked + label::after {
	content: "";
}

.checkbox input[type="checkbox"]:hover + label::before {
	outline: rgb(59, 153, 252) auto 5px;
}

.contentBox {
	text-align: center;
	font-family: "Oswald", sans-serif;
	font-size: 1.1rem;
	padding: 1.5rem 2rem;
	border-radius: 15px;
	box-shadow: 0px 0px 20px var(--shadow-color);
	background-color: var(--quick-color);
	color: var(--text-color);
	max-width: 90%;
	box-sizing: border-box;
}
.contentBox h3 {
	margin-top: 0;
	font-size: 150%;
}
#checkboxes {
	display: flex;
	justify-content: center;
	align-content: space-between;
	flex-wrap: wrap;
}

main {
	display: flex;
	align-items: center;
	flex-direction: column;
	font-size: 3rem;
	padding-bottom: 1em;
}

main .timerBox {
	max-width: 2000px;
	min-width: 900px;
	background-color: var(--quick-color);
	width: 70%;
	margin: 0.5em 0;
	color: var(--text-color);
	border-radius: 15px;
	box-shadow: 0px 0px 20px var(--shadow-color);
	overflow: hidden;
}

main .timerTitle {
	font-family: "Oswald", sans-serif;
	font-display: swap;
	display: flex;
	justify-content: center;
	font-size: 4rem;
	padding: 2rem;
	margin: 0;
	color: var(--text-color);
	border-bottom: 1px solid var(--seperator-color);
}

.timerTimes {
	font-size: 4rem;
	font-family: "Roboto Mono", monospace;
	letter-spacing: 0;
	padding: 2rem 0;
	width: 100%;
	height: 8rem;
	display: flex;
	justify-content: center;
	align-content: center;
	background: var(--quick-invert-color);
	color: white;
}

.timerTimes > p {
	margin: 2%;
	align-self: center;
}

/*Ghetto solution to a ghetto problem */
.quickBox .timerBox {
	height: 15em;
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 40% 60%;
	position: relative;
}

.quickBox .timerTimes {
	font-size: 3rem;
	padding: 0;
	height: auto;
}

#menu > a:hover {
	color: blue;
}

.close {
	color: black;
	background-color: white;
	position: fixed;
	top: 10px;
	width: 1em;
	display: flex;
	justify-content: center;
	right: 25px;
	font-size: 2em;
	font-weight: bold;
}

.close:hover {
	cursor: pointer;
	background-color: #d8d8d8;
	color: red;
}

#image {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	max-width: 90%;
	max-height: 90%;
	margin: auto;
	overflow: auto;
}

.modal:empty {
	display: none;
}

.modal {
	display: blocK;
	position: fixed;
	z-index: 1;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
	display: flex;
	font-family: oswald;
	font-display: swap;
	text-align: center;
	flex-direction: column;
	background-color: #fefefe;
	margin: 15% auto;
	/* 15% from the top and centered */
	padding: 20px;
	border: 1px solid #888;
	width: 80%;
	/* Could be more or less, depending on screen size */
}

.modal-content > h1 {
	color: black !important;
}

.modal-content > a {
	text-decoration: none;
	color: black;
}

.modal-content > a:hover {
	cursor: pointer;
	text-decoration: none;
	color: lightblue;
}

.modal-content {
	margin: auto;
	width: 80%;
}

.grid-container {
	display: grid;
	grid-template-columns: auto auto auto auto auto auto;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.5);
}

.item1 {
	grid-row: 1 / span 3;
	/* tässä kohtaa määritellään että pystysuuntainen kuva on kolmen normaalinkuvan korkuinen */
}

.modal.image {
	display: none;
	flex-direction: column;
	padding: 20px;
	width: 100vw;
	background-color: rgba(0, 0, 0, 0.8);
}

.modal-content.image {
	margin: auto;
	width: 80%;
	display: flex;
	font-family: oswald;
	font-display: swap;
	text-align: center;
	background-color: transparent;
	flex-direction: column;
	margin: 15% auto;
	padding: 20px;
	border: none;
	width: 80%;
}

.modal-content.image > img {
	border: solid 2px var(--seperator-color);
}

#picture1 {
	max-width: 98%;
	height: auto;
}

.pictures:hover {
	margin: 0;
	border: solid 5px var(--seperator-color);
	filter: grayscale(50%) blur(0.5px);
}

.pictures {
	max-width: 98%;
	height: auto;
	padding-right: 2px;
	border: solid 5px transparent;
}

.bottom {
	grid-row: 2;
}

.bottom1 {
	grid-row: 3;
}

@keyframes fadein {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes example {
	from {
		margin: 6px;
	}

	to {
		position: fixed;
		bottom: 0;
	}
}

.loginSite {
	display: flex;
	justify-content: space-between;
	min-width: 100vw;
	min-height: 100vh;
}

.lomaEsittely {
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	padding: 0 1em;
	height: 100vh;
	width: 40vw;
	min-height: 100vh;
	font-family: "Cabin Condensed", sans-serif;
}

.lomaEsittely > div {
	margin-left: 2vw;
	margin-bottom: 15vh;
}

.lomaEsittely h1 {
	margin: 0.3em;
}

#terveDiv {
	animation: appearLeft 1s forwards;
}

#lomaDiv {
	opacity: 0;
	animation: appearLeft 1s forwards;
	animation-delay: 0.2s;
}

#terveText {
	font-size: 3vw;
	transform: scaleX(1.4) translate(17%, 1.2%);
	color: var(--header-color);
}

#lomaText {
	transform: scaleY(1.4);
	font-size: 5vw;
	margin-left: 1em;
	color: var(--seperator-color);
}

.loginSite.closing .lomaEsittely {
	animation: appearLeft 0.5s reverse forwards;
}

.loginSite.closing .login {
	animation: appearRight 0.5s reverse forwards;
}

.login {
	display: flex;
	flex-direction: column;
	position: relative;
	align-items: flex-start;
	justify-content: center;
	background: var(--login-bg) left repeat-y;
	background-size: 100% auto;
	font-family: "Cabin Condensed", sans-serif;
}

.login > h2 {
	color: var(--header-color);
}

.darkIcon {
	position: fixed;
	right: 20px;
	top: 20px;
	font-size: 25px;
	transition: 0.5s filter ease-out;
	background: var(--mobile-menu-color);
	border-radius: 10px;
	transform: rotate(-45deg);
	height: 1.5em;
	/*filter: hue-rotate(0) drop-shadow(0 0 2px var(--seperator-color)); */
	box-shadow: 0px 0px 20px black;
	width: 1.5em;
}

.loginBox {
	box-shadow: 0px 0px 20px var(--shadow-color);
	background-color: var(--mobile-menu-color);
	border-radius: 15px;
	display: flex;
	width: auto;
	height: auto;
	justify-content: space-between;
	flex-direction: column;
}

.loginOptions {
	padding: 5px;
	color: whitesmoke;
	margin: 0.5em 1em 0.5em 1em;
	overflow-y: auto;
	scrollbar-color: var(--header-color) var(--background-color);
	scrollbar-width: thin;
	height: 13em;
	width: 35vw;
	max-width: 20em;
}

.loginOptions::-webkit-scrollbar {
	width: 8px;
	background-color: var(--background-color);
	border-radius: 8px;
}

.loginOptions::-webkit-scrollbar-thumb {
	background-color: var(--header-color);
	border-radius: 8px;
	height: 4px;
}

.schoolSelection {
	font-size: 1em;
	text-align: left;
	background: transparent;
	border: none;
	text-decoration: underline;
	font-family: "Cabin Condensed", sans-serif;
	cursor: pointer;
}

#infoLink {
	color: var(--text-color);
	text-decoration: none;
	font-size: 80%;
	background-color: var(--mobile-menu-color);
	border-radius: 15px;
	padding: 0.5em;
	border: none;
	position: absolute;
	right: 1vmax;
	bottom: 1vmax;
	transition: filter 0.5s ease-out, color 0.5s ease-out;

	box-shadow: 0px 0px 20px black;
}

#infoLink:hover {
	color: var(--text-color);
}

#logo {
	font-family: "Cabin Condensed", sans-serif;
	pointer-events: none;
	transform: scaleX(1.6);
	margin: 0;
	width: calc(100vw / 1.6);
	text-align: center;
	font-size: 8vw;
	color: var(--header-color);
	justify-content: center;
}

.loginOptions > h2,
.loginOptions > p,
.loginOptions > button {
	color: var(--text-color);
}

#menu {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background: var(--background-color);
}

@media (max-width: 960px) {
	.schoolSelection {
		min-height: 2.5em;
	}

	.Stat {
		font-size: 3vw;
		margin: 0.1em;
		padding: 0em 1em 0em 1em;
		font-family: "Roboto-mono", monospace;
	}

	#typingDiv > p {
		font-size: calc(4px + 2vw);
	}

	.btn {
		font-size: 3vw;
	}

	.backLink {
		height: 100%;
		padding: 1% 1%;
		box-sizing: border-box;
		position: absolute;
		left: 0px;
		top: 0px;
	}

	.menuBtn {
		height: 100%;
		filter: invert(var(--invert-amount));
	}

	.quickText {
		font-size: 3vw;
	}

	.quickBox {
		margin-left: 1em;
		margin-right: 1em;
	}

	.quickContent {
		height: 10em;
	}

	.arrow > svg {
		width: 6vw;
	}

	.quickTitle {
		font-size: calc(10px + 3vw);
	}

	.quickBoxLeft .timerTitle {
		font-size: calc(10px + 2.5vw);
		color: var(--text-color);
		text-shadow: none;
	}

	.quickContainer {
		grid-template-columns: 100%;
	}


	#menu {
		transition: 2s;
		transition-property: background-color;
	}

	.selectorDarkIcon {
		display: inline-block;
		position: static;
		background: var(--login-bg);
		border-radius: 15px;
		margin: 1em;
		transform: rotate(-45deg);
		height: 10vw;
		width: 10vw;
		/*filter: hue-rotate(0) drop-shadow(0 0 10px var(--seperator-color)); */
		box-shadow: 0px 0px 20px black;
	}

	.darkIcon:hover,
	#infoLink:hover {
		filter: hue-rotate(150deg) drop-shadow(0 0 10px var(--seperator-color));
	}

	.lomaEsittely {
		display: none;
	}

	#loginContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.loginBox {
		border-radius: 2rem;
	}

	.login {
		justify-content: flex-start;
		align-items: center;
		box-sizing: border-box;
		padding: 0 1em;
		min-height: 100vh;
		height: auto;
		width: 100vw;
		font-size: 4.25vw;
	}

	.loginOptions h2 {
		font-size: 1.5em;
	}

	.loginOptions {
		font-size: 1.3em;
		display: flex;
		flex-direction: column;
		width: auto;
		height: auto;
	}

	#infoLink {
		position: static;
		bottom: 0;
		text-align: center;
		font-size: 1.3em;
		margin: 1em 0em;
		padding: 0.5em 1em;
	}

	.loginOptions > button:hover {
		color: grey;
	}

	.milliseconds {
		width: 3em;
	}

	.noShake {
		width: 3em;
	}

	#menuContainer {
		position: fixed;
		width: 100%;
		z-index: 99;
		top: 0;
	}

	#content {
		margin-top: 12vw;
	}

	#menu {
		border-bottom: solid 1px;
		border-color: var(--seperator-color);
		flex-direction: row;
		height: 12.5vw;
	}

	#logo {
		font-size: 8vw;
		transition: 0.2s;
		transition-property: margin;
	}

	#menuBtn {
		display: inline-block;
		cursor: pointer;
	}

	#places {
		display: none;
	}

	main .timerBox {
		width: 90%;
		min-width: 0;
	}

	main .timerTitle {
		font-size: 6vw;
		padding: 0.5em;
	}

	main .timerTimes {
		font-size: 6vw;
		padding: 6vw 0;
		height: 12vw;
	}

	main.millis .timerTimes {
		font-size: 4vw;
	}
	.quickBox .timerBox {
		height: 10em;
	}
	.quickBox .timerTimes {
		font-size: 6vw;
	}
	.quickContent h2 {
		font-size: 5vw;
	}
}

#tilastot {
	text-align: center;
	display: flex;
	align-content: center;
	justify-content: center;
	flex-direction: column;
	font-family: oswald;
	font-display: swap;
	background-color: rgba(255, 255, 255, 0.2);
}

.result {
	font-size: 3em;
	color: red;
	/* Tilastojen negatiivisen vastauksen väri */
}

.positiveResult {
	font-size: 3em;
	color: green;
	/* Positiivisen vastauksen väri */
}

.kysely {
	display: flex;
	justify-content: center;
}

#cookie {
	position: sticky;
	bottom: 0;
}

@media (min-width: 960px) {
	.loginSite {
		overflow: hidden;
	}

	.schoolSelection {
		min-height: 35px;
	}

	.quickText {
		font-size: 1.5em;
	}

	.quickBox:nth-child(odd) {
		margin-left: 1em;
		margin-right: 1em;
	}

	.quickBox:nth-child(even) {
		margin-left: 0em;
		margin-right: 1em;
	}

	.quickContent {
		height: 15em;
	}

	.quickBoxLeft .timerTitle.corona {
		font-size: 2.25em;
	}

	.quickBoxLeft .timerTitle {
		font-size: 3em;
		color: var(--text-color);
		text-shadow: none;
	}

	.quickContainer {
		grid-template-columns: 50% 50%;
	}

	#foodList {
		min-width: 900px;
		max-width: 2000px;
		width:70%;	
	}

	.quickBoxLeft .timerTimes {
		font-size: 3rem;
	}

	main.millis .timerTimes {
		font-size: 3rem;
	}

	.Stat {
		font-size: 1.5em;
		margin: 0.1em;
		padding: 0em 1em 0em 1em;
	}

	.selectorDarkIcon {
		display: inline;
		position: static;
		background: var(--mobile-menu-color);
		margin-left: 3em;
		border-radius: 10px;
		transform: rotate(-45deg);
		height: 1.5em;
		/*filter: hue-rotate(0) drop-shadow(0 0 2px var(--seperator-color)); */
		box-shadow: 0px 0px 20px black;
		width: 1.5em;
	}

	.darkIcon:hover,
	#infoLink:hover {
		filter: hue-rotate(150deg) drop-shadow(0 0 5px var(--seperator-color));
		color: grey;
	}

	#loginContainer {
		display: flex;
		align-items: center;
		justify-content: space-around;
		width: auto;
	}

	.login {
		box-sizing: border-box;
		padding-left: 8%;
		width: 60vw;
		max-width: 1200px;
		height: 100vh;
		font-size: 28px;
		margin-left: 2em;
	}

	.loginBox {
		margin-left: 2em;
		font-size: 0.8em;
	}

	.loginOptions {
		display: flex;
		flex-direction: column;
		max-height: 45vh;
	}

	.loginOptions > button:hover {
		color: grey;
	}

	.menuBtn {
		display: none;
	}

	#anotherPage {
		display: none;
	}

	#places {
		display: flex !important;
		justify-content: center;
		margin: 20px 5%;
		padding: 1em;
		box-shadow: var(--shadow-color) 0px 0px 5px 2px inset;
		background-color: var(--quick-color);
		border-radius: 1em;
	}

	#places > a {
		text-decoration: none;
		margin: 0 1em 0 1em;
		font-size: 20px;
		font-family: oswald;
		font-display: swap;
	}

	.noShake {
		width: 3em;
	}

	#dynaaminenNappi {
		cursor: pointer;
	}
}

@media (max-width: 1500px) and (min-width: 960px) {
	.selectorDarkIcon {
		position: fixed;
		display: absolute;
		top: 20px;
		right: 20px;
	}
}
